<ng-container *transloco="let t">
  <nav class="topbar">
    <div class="topbar__search">
      <p-button
        icon="pi pi-bars"
        class="topbar__menu-icon topbar--show-lg"
        [attr.aria-label]="t('openMenu')"
        (click)="toggleSidebar(true)"></p-button>
      <span class="p-input-icon-left">
        <i class="pi pi-search" aria-hidden="true"></i>
        <input
          type="text"
          pInputText
          [placeholder]="t('quickSearch')"
          disabled />
      </span>
    </div>
    <div class="topbar__icons">
      <p-button
        icon="pi pi-bars"
        ariaLabel="Open menu"
        class="topbar__menu-icon topbar--show-sm"
        (click)="toggleSidebar(true)"></p-button>
      <div class="topbar__icons-list">
        <p-button
          class="topbar__icons-list__button"
          icon="pi pi-list"
          [attr.aria-label]="t('toDo')"
          [disabled]="true"
          [rounded]="false"
          [text]="true">
          <span class="topbar__icons-list__button__text">{{ t('toDo') }}</span>
          @if (toDosNo) {
            <span class="topbar__icons-list__button__badge">{{ toDosNo }}</span>
          }
        </p-button>
        <p-button
          class="topbar__icons-list__button"
          icon="pi pi-envelope"
          [attr.aria-label]="t('messages')"
          [disabled]="true"
          [rounded]="false"
          [text]="true">
          <span class="topbar__icons-list__button__text">{{
            t('messages')
          }}</span>
          @if (messagesNo) {
            <span class="topbar__icons-list__button__badge">{{
              messagesNo
            }}</span>
          }
        </p-button>
        <p-button
          class="topbar__icons-list__button"
          icon="pi pi-cog"
          [attr.aria-label]="t('settings')"
          [disabled]="true"
          [rounded]="false"
          [text]="true">
          <span class="topbar__icons-list__button__text">{{
            t('settings')
          }}</span>
        </p-button>
      </div>
    </div>
  </nav>
</ng-container>
