<ng-container *transloco="let t">
  <aside class="sidebar" role="complementary">
    <div class="sidebar__logo logo">
      <img
        src="../../../assets/logos/dnv-logo.svg"
        alt="DNV logo"
        class="logo--lg" />
      <img
        src="../../../assets/logos/dnv-logo-sm.svg"
        alt="DNV logo small"
        class="logo--sm" />
      <i
        class="pi pi-times sidebar__close"
        role="button"
        tabindex="0"
        (click)="hideSidebar.emit()"
        (keydown.enter)="hideSidebar.emit()"
        (keydown.space)="hideSidebar.emit()"></i>
    </div>
    <nav class="sidebar__menu" role="navigation">
      @for (item of menuItems(); track item.label) {
        <p-button
          (onClick)="item?.routerFunc(); hideSidebar.emit()"
          [icon]="item.icon"
          [label]="item.label | uppercase"
          [text]="true"
          [disabled]="item.disabled"
          [ngClass]="[
            'sidebar__menu-item',
            'menu-item',
            item.customClass || '',
            item.isActive ? 'menu-item--active' : ''
          ]"
          attr.aria-label="{{ item.label | uppercase }}">
        </p-button>
      }
    </nav>
    <div class="sidebar__support-box" role="complementary">
      <customer-portal-support-box></customer-portal-support-box>
    </div>
  </aside>
</ng-container>
