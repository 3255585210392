import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'customer-portal-breadcrumb',
  standalone: true,
  imports: [CommonModule, BreadcrumbModule, TranslocoDirective],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  private readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  home: MenuItem = { icon: 'pi pi-home', routerLink: '/', target: '_self' };
  showBreadcrumb = signal(false);
  menuItems: WritableSignal<MenuItem[]> = signal([]);

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        tap((e) => this.showBreadcrumb.set(e.url.split('?')[0] !== '/')),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.menuItems.set(this.createBreadcrumbs(this.activatedRoute.root));
      });
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: MenuItem[] = [],
  ): MenuItem[] {
    const { children }: { children: ActivatedRoute[] } = route;
    const routerLink: string[] = [];

    if (!children?.length) {
      return breadcrumbs;
    }

    children.forEach((child) => {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');

      if (routeURL !== '') {
        routerLink.push(routeURL);
      }

      const label = child.snapshot.data[this.ROUTE_DATA_BREADCRUMB];

      if (label) {
        breadcrumbs.push({
          label: routeURL.charAt(0).toUpperCase() + routeURL.slice(1),
          routerLink,
        });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    });

    return breadcrumbs;
  }
}
