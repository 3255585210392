import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';

import { SpinnerComponent } from '@customer-portal/core';
import {
  CustomConfirmDialogComponent,
  CustomToastComponent,
} from '@customer-portal/shared';
import { ToastModule } from 'primeng/toast';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarMobileComponent } from './components/sidebar-mobile/sidebar-mobile.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NavbarComponent } from './components/navbar/navbar.component';

@Component({
  standalone: true,
  imports: [
    ButtonModule,
    RouterModule,
    TranslocoDirective,
    NavbarComponent,
    SpinnerComponent,
    SidebarComponent,
    SidebarMobileComponent,
    BreadcrumbComponent,
    CustomConfirmDialogComponent,
    CustomToastComponent,
    ToastModule,
  ],
  providers: [ConfirmationService],
  selector: 'customer-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'customer-portal';
  sidebarVisible = false;

  toggleSidebar(toggle: boolean): void {
    this.sidebarVisible = toggle;
  }
}
