@if (showBreadcrumb()) {
  <ng-container *transloco="let t">
    <div class="breadcrumb">
      <p-breadcrumb
        class="max-w-full"
        [homeAriaLabel]="t('Home')"
        [model]="menuItems()"
        [home]="home"></p-breadcrumb>
    </div>
  </ng-container>
}
