import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
  WritableSignal,
  signal,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SupportBoxComponent } from '../support-box/support-box.component';

interface SidebarMenuItem {
  icon: string;
  label: string;
  url: string;
  customClass?: string;
  isActive?: boolean;
  disabled?: boolean;
  routerFunc?: () => void;
}

@Component({
  selector: 'customer-portal-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    SupportBoxComponent,
    ButtonModule,
    TranslocoDirective,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  private currentUrl = '';

  @Output() hideSidebar = new EventEmitter<void>();

  menuItems: WritableSignal<SidebarMenuItem[]> = signal(this.getMenuItems());

  constructor(
    private ts: TranslocoService,
    private router: Router,
    private destroyRef: DestroyRef,
  ) {
    this.ts.langChanges$
      .pipe(
        switchMap((lang) => this.ts.load(lang)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.menuItems.set(this.getMenuItems());
        this.updateMenuItemsActiveUrl();
      });
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        startWith(this.router),
        map((e) => e.url.split('?')[0]),
        map((url: string) => url.split('/')[1]),
      )
      .subscribe((url: string) => {
        this.currentUrl = url;
        this.updateMenuItemsActiveUrl();
      });
  }

  onClickClose(): void {
    this.hideSidebar.emit();
  }

  private updateMenuItemsActiveUrl(): void {
    this.menuItems.update((menuItems: SidebarMenuItem[]) =>
      menuItems.map((i: SidebarMenuItem) => ({
        ...i,
        isActive: this.currentUrl?.includes(i.url),
      })),
    );
  }

  private getMenuItems(): SidebarMenuItem[] {
    return [
      {
        icon: 'pi pi-home',
        label: this.ts.translate('Home'),
        routerFunc: () =>
          this.router.navigate(['/'], { queryParamsHandling: 'preserve' }),
        disabled: true,
        url: '',
      },
      {
        icon: 'pi pi-chart-pie',
        label: this.ts.translate('Analytics'),
        customClass: 'menu-item__separator',
        disabled: true,
        url: 'analytics',
      },
      {
        icon: 'pi pi-check-square',
        label: this.ts.translate('Contracts'),
        disabled: true,
        url: 'contracts',
      },
      {
        icon: 'pi pi-calendar',
        label: this.ts.translate('Schedule'),
        routerFunc: () => this.router.navigate(['/schedules']),
        url: 'schedules',
      },
      {
        icon: 'pi pi-pencil',
        label: this.ts.translate('Audits'),
        routerFunc: () =>
          this.router.navigate(['/audits'], {
            queryParamsHandling: 'preserve',
          }),
        url: 'audits',
      },
      {
        icon: 'pi pi-exclamation-triangle',
        label: this.ts.translate('Findings'),
        routerFunc: () =>
          this.router.navigate(['/findings'], {
            queryParamsHandling: 'preserve',
          }),
        url: 'findings',
      },
      {
        icon: 'pi pi-file',
        label: this.ts.translate('Invoices'),
        disabled: true,
        url: 'invoices',
      },
      {
        icon: 'pi pi-check-circle',
        label: this.ts.translate('Certificates'),
        routerFunc: () => this.router.navigate(['/certificates']),
        customClass: 'menu-item__separator',
        url: 'certificates',
      },
      {
        icon: 'pi pi-book',
        label: this.ts.translate('Trainings'),
        disabled: true,
        url: 'trainings',
      },
      {
        icon: 'pi pi-th-large',
        label: this.ts.translate('MoreApps'),
        disabled: true,
        url: 'moreapps',
      },
    ];
  }
}
