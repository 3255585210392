<ng-container *transloco="let t">
  <div class="support-box">
    <div class="support-box__title">{{ t('HelpTitle') }}</div>
    <div class="support-box__message">
      {{ t('HelpBody') }}
    </div>
    <p-button [text]="true" [disabled]="true" class="support-box__link-text">
      {{ t('HelpLink') }}
    </p-button>
  </div>
</ng-container>
