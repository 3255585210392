<div class="home">
  <ng-container *transloco="let t">
    <customer-portal-navbar
      class="home__topbar"
      (displaySidebar)="toggleSidebar(true)"></customer-portal-navbar>
    <div class="home__sidebar">
      <customer-portal-sidebar></customer-portal-sidebar>
    </div>
    <div class="home__main-view">
      <lib-spinner></lib-spinner>
      <customer-portal-breadcrumb></customer-portal-breadcrumb>
      <router-outlet></router-outlet>
    </div>
    <customer-portal-sidebar-mobile
      class="home__sidebar-mobile"
      (hideSidebar)="toggleSidebar(false)"
      [sidebarVisible]="sidebarVisible"></customer-portal-sidebar-mobile>
  </ng-container>
</div>

<shared-custom-confirm-dialog></shared-custom-confirm-dialog>
<shared-custom-toast></shared-custom-toast>
