import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'customer-portal-navbar',
  standalone: true,
  imports: [ButtonModule, CommonModule, InputTextModule, TranslocoDirective],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @Output() displaySidebar = new EventEmitter<boolean>();

  toDosNo = 11;
  messagesNo = 11;

  toggleSidebar(toggle: boolean): void {
    this.displaySidebar.emit(toggle);
  }
}
