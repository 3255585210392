import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';

import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'customer-portal-sidebar-mobile',
  standalone: true,
  imports: [CommonModule, SidebarModule, SidebarComponent],
  templateUrl: './sidebar-mobile.component.html',
  styleUrl: './sidebar-mobile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMobileComponent {
  @Input() sidebarVisible = false;
  @Output() hideSidebar = new EventEmitter<void>();

  onHideSidebar(): void {
    this.hideSidebar.emit();
  }
}
