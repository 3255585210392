import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('@customer-portal/features/overview').then(
        (m) => m.OVERVIEW_ROUTES,
      ),
    data: {
      breadcrumb: null,
    },
    title: 'Overview',
  },
  {
    path: 'audits',
    loadChildren: () =>
      import('@customer-portal/features/audit').then((r) => r.AUDIT_ROUTES),
    data: {
      breadcrumb: 'Audits',
    },
    title: 'Audits',
  },
  {
    path: 'findings',
    loadChildren: () =>
      import('@customer-portal/features/findings').then(
        (r) => r.FINDINGS_ROUTES,
      ),
    data: {
      breadcrumb: 'Findings',
    },
    title: 'Findings',
  },
  {
    path: 'certificates',
    loadChildren: () =>
      import('@customer-portal/features/certificates').then(
        (r) => r.CERTIFICATE_ROUTES,
      ),
    data: {
      breadcrumb: 'Certificates',
    },
    title: 'Certificates',
  },
  {
    path: 'schedules',
    loadChildren: () =>
      import('@customer-portal/features/schedules').then(
        (r) => r.SCHEDULES_ROUTES,
      ),
    data: {
      breadcrumb: 'Schedules',
    },
    title: 'Schedules',
  },
];
